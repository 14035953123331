<template>
  <div class="available-upgrades tw-mb-5 pb-10">
    <v-container v-if="sessionUser">
      <v-row>
        <v-col cols="12" class="tw-text-left">
          <h2>Hi, {{ sessionUser.firstName }}!</h2>
          <p>
            Upgrades allow you to unlock premium features for your trip.
            <strong>Go to your Trip Hub to upgrade</strong> any trip that you’re
            attending.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
          <v-row align="center" class="tw-flex tw-py-3 tw-px-2 tw-items-center">
            <v-col cols="2">
              <img
                src="@/assets/svgs/premium/blue-diamond-solo.svg"
                class="tw-mr-3"
              />
            </v-col>
            <v-col cols="8">
              <span>Upgrades available</span>
            </v-col>
            <v-col cols="2" class="tw-text-right">
              <span>{{ wallet.credits }}</span>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <div class="what-you-get py-10">
        <v-row class="tw-text-left">
          <v-col>
            <h2>What You Get In First Class</h2>
          </v-col>
        </v-row>

        <v-row class="tw-text-left">
          <v-col cols="2">
            <img
              src="@/assets/svgs/premium/customized-invitations.svg"
              class="tw-mr-3"
            />
          </v-col>
          <v-col cols="10">
            <h3>Customize your invitation</h3>
            <p>Choose from a custom design to spice up your trip invite.</p>
          </v-col>
        </v-row>

        <v-row class="tw-text-left">
          <v-col cols="2">
            <img
              src="@/assets/svgs/premium/multiple-surveys.svg"
              class="tw-mr-3"
            />
          </v-col>
          <v-col cols="10">
            <h3>Unlimited notes & surveys</h3>
            <p>
              Get all of your questions answered by sending out as many surveys
              as your heart desires.
            </p>
          </v-col>
        </v-row>

        <v-row class="tw-text-left">
          <v-col cols="2">
            <img src="@/assets/svgs/premium/first-access.svg" class="tw-mr-3" />
          </v-col>
          <v-col cols="10">
            <h3>Be the first</h3>
            <p>
              You’ll be there first to access our new premium features before
              anyone else.
            </p>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <v-btn
            class="j-premium"
            block
            x-large
            depressed
            rounded
            dark
            :to="{ name: 'TripsRoute' }"
          >
            Go to My Trips to Upgrade
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AvailableUpgrades",

  data() {
    return {};
  },

  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    wallet() {
      return this.sessionUser.user_wallet;
    }
  },

  methods: {},

  async beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Trip Upgrades",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      showBackButton: true,
      endDate: null
    });

    // Dispatch the action to get trip details
    await this.$store.dispatch("users/get", this.sessionUser.id);
  },
  mounted() {},
  beforeUnmount() {}
};
</script>

<style lang="scss">
.available-upgrades {
  h2 {
    font-family: Figtree;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h3 {
    font-family: Figtree;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.02em;
    text-align: left;
  }

  .what-you-get {
    p {
      color: #949494;
    }
  }

  .j-premium-text {
    font-weight: 600;
    color: #1743bb;
  }
}
</style>
